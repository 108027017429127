import React, { Component } from 'react';

import Layout from "../components/layout";
import AboutTwoColumn from "../components/AboutTwoColumn"
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import topDown from "../images/cityTopdown.jpg";
import timesSquare from "../images/timesSquare.jpg"
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";
import ContactButtons from "../components/ContactButtons";
import CallToAction from "../components/CallToAction";


export default function About() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About</title>
                <link rel="canonical" href="http://www.newnewyorkcapital.com/about" />
                <link rel="icon" href={favicon} />
            </Helmet>
            <Layout>
                <AboutTwoColumn />
                <h1 style={{ width: "100%", textAlign: "center" }} className="titleFont">About Our Partnership</h1>
                <div style={{ marginTop: "4em" }} className="row">
                    <div className="col-sm-6">
                        <h3 className="titleFont">Founding Team</h3>
                        <p>
                            The CEO has lived in New York City since 2014. He began his professional career working with some of the best investment managers in the world at a renowned hedge fund where he’s allocated billions of dollars across asset classes. His other interests and pursuits include urban life and design, scientific research, surfing, skiing, and philosophy.
                        <br />
                            <br />
                        The other partners wish to remain nameless at this time.
                    </p>
                    </div>
                    <div className="col-sm-6">
                        <img style={{ width: "100%" }} src={topDown} />
                    </div>
                </div>
                <div style={{ marginTop: "4em" }} className="row">
                    <div className="col-sm-6">
                        <img style={{ width: "100%" }} src={timesSquare} />
                    </div>
                    <div className="col-sm-6">
                        <h3 className="titleFont">We’re carefully vetting potential partners to join our mission in various capacities not limited to</h3>
                        <p>
                            Education and blogging<br />
                        Software investments<br />
                      
                    </p>
                    </div>
                </div>
            </Layout>
            <Footer />
        </div>
    )
}
