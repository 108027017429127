import React, { Component } from 'react';
import NewYork from '../images/skyline.jpg';

function HomeCircle(props) {
    return (
        // <img clasName="" src={NewYork}></img>
        <svg
            width="75%"
            viewBox="0 0 578 573"
            preserveAspectRatio="xMidYMid meet"
            // width={578}
            // height={573}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs> <pattern id="pattern" patternUnits="userSpaceOnUse" width="1667" height="733"> <image href="https://i.ibb.co/hMhhx1t/NYC-Manhattan-Skyline-2-1.jpg" x="0" y="0" width="1667" height="733" > </image> </pattern> </defs>
            <circle cx={313.5} cy={308.5} r={264.5} fill="#00B6DE" />
            <path
                d="M240.711 261.26c-50 103.601-148.833 95.5-192 78.5-192-92 231 566 446.5 0s-45-78.5-178-270.5-14 62.5-76.5 192z"
                fill="url(#pattern)"
            />

            <defs>
                <pattern id="skyline" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use transform="translate(-0.625475) scale(0.000619073)" />
                </pattern>
            </defs>
        </svg >
    );
}

export default HomeCircle;