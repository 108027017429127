import React from "react";
import ContactButtons from "./ContactButtons";
import "./Footer.css";

function Footer() {
    return (
        <div className="main-footer">
            <div className="container">
                <div className="row">
                    {/* Column1 */}
                    <div className="col">

                    </div>
                    {/* Column2 */}
                    <div className="col">

                    </div>
                    {/* Column3 */}
                    <div className="col">

                    </div>
                </div>
                <hr />
                <div className="row">
                    <p className="col-sm">
                        &copy;{new Date().getFullYear()} New New York Capital | All rights reserved | Terms Of Service | Privacy
                    </p>
                    <div className="col-sm" style={{ marginBottom: "2em" }}>
                        <ContactButtons className="footerContact" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;