import React, { Component } from 'react';
import "./callToAction.css";

function CallToAction({ props, children }) {
    return (
        <div className="buttonContainer">
            <button onClick={(e) => { openUrl('/education') }} style={{ paddingLeft: "1em", height: "50px", backgroundColor: "#00B6DE", color: "white" }} className="btn callToAction">
                {children}
                <svg style={{ float: "right" }} width="30" height="25" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 35L31.5 27.5L24 20L25.5 17L36 27.5L25.5 38L24 35Z" fill="white" />
                </svg>
            </button>
        </div>
    );
}

function openUrl(url) {
    window.open(url, '_blank');
}
export default CallToAction;