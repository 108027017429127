import React, { Component } from 'react';
import "./twoColumn.css";
import HomeCircle from "./homeCircle";

class AboutTwoColumn extends Component {
    render() {
        return (
            <div className="row fullTwoColumn flex-column-reverse flex-lg-row">
                <div className="col-lg-6 verticleCenter">
                    <div className="titleText align-self-center ">
                        <div className="subTitle">
                            About us:
                        </div>

                        <p style={{ fontWeight: '300' }}>
                            Find out how we're working towards our mission
                        </p>



                    </div>
                </div>
                <div className="col-lg-6">
                    <HomeCircle className="circleImage" />
                </div>
            </div>
        );
    }
}

export default AboutTwoColumn;